.modal {
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  align-items: center;
  background: rgba(1, 22, 61, 0.8);
  transition: 0.3s;
}
.modal__content {
  display: block;
  position: relative;
  margin: auto;
  padding: 18px;
  border-radius: 10px;
  background-color: #fff;
  overflow-y: auto;
  width: 35rem;
  transition: 0.3s;
}
.class__close__img{
    position: absolute;
    right: 20px;
    height: 20px;
    width: 20px;
    background-color: var(--red);
    border-radius: 100%;
    cursor: pointer;
}
.submit{
    background: #4154f1;
    border: 0;
    padding: 10px 30px;
    color: #fff;
    transition: 0.4s;
    border-radius: 4px;
}